@use 'libs/styles/variables';
@use 'libs/styles/layout';

.container {
  @include layout.column(32px);
  align-items: center;
  min-height: 80svh;
}

.logo {
  @include layout.column(16px);
  @include variables.header(4);
  align-items: center;
  margin-block: 1.5rem;
}
